var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('h5',{staticClass:"mt-4 mb-4"},[_vm._v(_vm._s(_vm.$_strings.order.ROUTE_INFO))]),_c('v-data-table',{staticClass:"elevation-1 rounded",attrs:{"headers":_vm.headerRouteInfo,"calculate-widths":"","hide-default-footer":"","items":_vm.routes},scopedSlots:_vm._u([{key:"item.cargoNumber",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"body-2 pa-0 light-blue--text",attrs:{"text":""},on:{"click":function($event){return _vm.showDialogCargoStatus(item)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item.cargoNumber)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.VIEW_STATUS_CARGO))])])]}},{key:"item.orderDate",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.dateFormat(item.pickupDate)))]),_c('p',[_vm._v(_vm._s(_vm.timeFormat(item.pickupDate)))])]}},{key:"item.originalLocation",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.originalLocation.name ? item.originalLocation.name : item.originalLocation))])]}},{key:"item.destinationLocation",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.destinationLocation.name ? item.destinationLocation.name : item.destinationLocation))])]}},{key:"item.estimateArrival",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.dateFormat(item.estimateArrival)))]),(item.estimateArrival)?_c('p',[_vm._v(_vm._s(_vm.timeFormat(item.estimateArrival)))]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0",class:_vm.getColorStatus(item.status)},[_vm._v(_vm._s(item.status))])]}}],null,true)}),_c('DialogCargoStatus',{ref:"dialogCargoStatus"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }