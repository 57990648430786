<template>
  <section>
    <h5 class="mt-4 mb-4">{{$_strings.order.ROUTE_INFO}}</h5>
    <v-data-table
      :headers="headerRouteInfo"
      calculate-widths
      hide-default-footer
      :items="routes"
      class="elevation-1 rounded"
    >
      <template v-slot:[`item.cargoNumber`]={item}>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              class="body-2 pa-0 light-blue--text"
              @click="showDialogCargoStatus(item)"
            >
              {{item.cargoNumber}}
            </v-btn>
          </template>
          <span>{{$_strings.order.VIEW_STATUS_CARGO}}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.orderDate`]={item}>
        <p class="ma-0">{{dateFormat(item.pickupDate)}}</p>
        <p>{{timeFormat(item.pickupDate)}}</p>
      </template>
      <template v-slot:[`item.originalLocation`]={item}>
        <p class="ma-0">{{item.originalLocation.name ? item.originalLocation.name : item.originalLocation}}</p>
      </template>
      <template v-slot:[`item.destinationLocation`]={item}>
        <p class="ma-0">{{item.destinationLocation.name ? item.destinationLocation.name : item.destinationLocation}}</p>
      </template>
      <template v-slot:[`item.estimateArrival`]={item}>
        <p class="ma-0">{{dateFormat(item.estimateArrival)}}</p>
        <p v-if="item.estimateArrival">{{timeFormat(item.estimateArrival)}}</p>
      </template>
      <template v-slot:[`item.status`]={item}>
        <p class="ma-0" :class="getColorStatus(item.status)">{{item.status}}</p>
      </template>
    </v-data-table>
    <DialogCargoStatus
      ref="dialogCargoStatus"
    />
  </section>
</template>

<script>
import { dateFormat, timeFormat } from '@/helper/commonHelpers';

const DialogCargoStatus = () => import(/* webpackChunkName: "DialogCargoStatus" */ '../Dialog/CargoStatus.vue');

export default {
  components: {
    DialogCargoStatus,
  },
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headerRouteInfo: [
        {
          text: this.$_strings.order.NO_GRUP_CARGO,
          value: 'cargoGroupNumber',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.NO_CARGO,
          value: 'cargoNumber',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.VEHICLE,
          value: 'transportName',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.TRANSPORTER_NAME,
          value: 'companyTransport',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.VEHICLE_NUMBER,
          value: 'transportNumber',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.SEND_DATE,
          value: 'orderDate',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.ORIGIN,
          value: 'originalLocation',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'destinationLocation',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.ETA,
          value: 'estimateArrival',
          width: '110px',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.CARGO_STATUS,
          value: 'status',
          class: 'black--text amber accent-3',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    dateFormat,
    timeFormat,
    getColorStatus(status) {
      const _status = status.toLowerCase();
      const redStatus = ['dibatalkan', 'ditolak', 'kedaluwarsa', 'dokumen tidak lengkap'];
      const greenStatus = ['barang diterima utuh', 'barang diterima tidak utuh'];
      if (redStatus.includes(_status)) return 'red--text';
      if (greenStatus.includes(_status)) return 'green--text';
      return 'black--text';
    },
    showDialogCargoStatus(item) {
      this.$refs.dialogCargoStatus.cargoNumber = item.cargoNumber;
      this.$refs.dialogCargoStatus.shipmentsCargoId = item.shipmentsCargoId;
      this.$refs.dialogCargoStatus.dialog = true;
    },
  },
};
</script>
